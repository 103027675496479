import * as React from "react";
import { graphql } from "gatsby";
import HomeLayout from "../components/HomeLayout";
import { getCurrentLangKey } from "../utils/lang";
import SearchBox from "../components/SearchBox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";

const Section3 = styled.section`
  -webkit-mask-image: url(/images/oval.png);
  mask-image: url(/images/oval.png);
  mask-size: 98% 98%;
  width: 362px;
  height: 519px;
  mask-repeat: no-repeat;
  mask-position: center;
`;

const GradiantSection = styled.div`
  background: hsla(152, 100%, 50%, 1);

  background: linear-gradient(
    90deg,
    hsla(152, 100%, 50%, 1) 0%,
    hsla(186, 100%, 69%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(152, 100%, 50%, 1) 0%,
    hsla(186, 100%, 69%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(152, 100%, 50%, 1) 0%,
    hsla(186, 100%, 69%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#00FF87", endColorstr="#60EFFF", GradientType=1 );
`;

const Badge = styled.span`
  background: linear-gradient(to right, #007fff, #0059b2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Badge2 = styled.span`
  background-color: #34a853;
  background-image: linear-gradient(180deg, #34a853, #4285f4);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

// markup
const IndexPage = (props) => {
  const data = props.data;
  const location = props.location;
  const url = location.pathname;
  const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const jsonData = data.articles.edges[0].node.articles;
  const languages = data.site.siteMetadata.languages;
  const id = data.homepage.nodes[0].frontmatter.id;
  const lang = langKey;

  return (
    <HomeLayout
      title={data.markdownRemark.frontmatter.title}
      jsonData={jsonData}
      languages={languages}
      location={location}
      id={id}
      description={data.markdownRemark.frontmatter.description}
    >
      <title>{data.markdownRemark.frontmatter.title}</title>
      {/* <HomeHeroPage
        header={data.markdownRemark.frontmatter.title}
        langKey={lang}
        headerDescription={data.markdownRemark.frontmatter.title}
      /> */}
      <Box
        sx={{
          mt: 8,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            {/* <GradiantSection> */}
            <Typography align='center' variant='h1'>
              <Badge>Our World Publications</Badge>
            </Typography>
            <Typography
              align='center'
              color='textSecondary'
              variant='h3'
              sx={{ py: 3 }}
            >
              <Badge>Bridging the Stories between You and the World</Badge>
            </Typography>
            <Section3></Section3>
            {/* </GradiantSection> */}
          </Grid>
        </Box>
      </Box>
    </HomeLayout>
    // </AppContext.Provider>
  );
};

export default IndexPage;

/*
1. We do have two variables: id and lang which are being passed to the query. 
In the below query the lang and id variables are being defined in react component then we will passing it through the qraphql query.
2. The query is selecting the markdownRemark node.
*/

export const query = graphql`
  query TheHomeQueryAll($id: String!, $lang: String) {
    homepage: allMarkdownRemark(filter: { frontmatter: { id: { eq: "03" } } }) {
      nodes {
        frontmatter {
          id
          slug
          title
          lang
          description
          date
          author
        }
        internal {
          content
        }
        html
      }
    }
    markdownRemark(id: { eq: $id }, fields: { langKey: { eq: $lang } }) {
      frontmatter {
        id
        slug
        title
        lang
        description
        date
        author
      }
      internal {
        content
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    articles: allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            sv
          }
        }
      }
    }
    en: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { lang: { eq: "en" }, template: { eq: "blogbody" } }
      }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            author
            slug
            lang
            date
          }
          excerpt
        }
      }
    }
    sv: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { lang: { eq: "sv" }, template: { eq: "blogbody" } }
      }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            author
            slug
            lang
            date
          }
          excerpt
        }
      }
    }
  }
`;
